import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {Stepper, Step, StepButton, StepConnector, Tabs, Tab, Typography, Grid, Box, SvgIcon, CircularProgress} from '@material-ui/core';
// import VerifiedUserSharp from '@material-ui/icons/VerifiedUserSharp';
import Check from '@material-ui/icons/Check';
import FilledCircle from '@material-ui/icons/Brightness1';
import ShortTermIcon from '@material-ui/icons/Brightness7';
// import ShortTermIcon1 from '@material-ui/icons/NewReleases';
// import MediumTermIcon from '@material-ui/icons/FilterVintage'
import Info from '@material-ui/icons/InfoOutlined';
import DownArrow from '@material-ui/icons/ArrowDropDownOutlined';
import ReactToolTip from 'react-tooltip';
import SingleChallengeReflections from './SingleChallengeReflections';
import { progressBackgroundColor, tooltipIconColor } from '../../../../constants';
import { toolTipContent } from '../../../../services/helpers';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../../../utilities/localisation/translationKeys';

const InProgressGoal = (props) => (
  <SvgIcon { ...props }>
    <path fill="currentColor" d="m20 22l-3.86-1.55c.7-1.53 1.2-3.11 1.51-4.72zM7.86 20.45L4 22l2.35-6.27c.31 1.61.81 3.19 1.51 4.72M12 2s5 2 5 10c0 3.1-.75 5.75-1.67 7.83A2 2 0 0 1 13.5 21h-3a2 2 0 0 1-1.83-1.17C7.76 17.75 7 15.1 7 12c0-8 5-10 5-10m0 10c1.1 0 2-.9 2-2s-.9-2-2-2s-2 .9-2 2s.9 2 2 2"></path>
  </SvgIcon>
);

const findActiveStep = (stepArray) => {
  for (let i = 1; i < stepArray.length; i++) {
    if (!stepArray[i].complete && stepArray[i].attempted && stepArray[i - 1].complete) return i;
  }
  if (stepArray[0] && stepArray[0].attempted) return 0;
  return undefined;
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={ value !== index }
      id={ `vertical-tabpanel-${ index }` }
      aria-labelledby={ `vertical-tab-${ index }` }
      { ...other }
    >
      <Box p={ 1 } paddingLeft='12px'>{ children }</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${ index }`,
    'aria-controls': `vertical-tabpanel-${ index }`,
  };
}

const TabComponent = React.forwardRef((props, ref) => {
  let IconComponent = <InProgressGoal htmlColor='#00000000' />;
  
  if (props.status === 'complete') {
    IconComponent = <InProgressGoal htmlColor='#589F1C' />;
  } 
  else if (props.status === 'started') {
    IconComponent = <InProgressGoal htmlColor='black'/>;
  }
  const {
    inGame,
    outOfGame,
    goalName,
    ...restProps
  } = props;
  return (
    <div ref={ ref } { ...restProps } style={ { display: 'flex', height: '48px' } }>
      <div style={ { display: 'flex', margin: 'auto' } }>
        { IconComponent }<Typography component='span' style={ {
        paddingRight: '4px',
        paddingLeft: '4px',
        textAlign: 'center',
        textTransform: 'none'
      } }>{ props.goalName }</Typography>
      </div>
    </div>
  );
});

export const ChallengeIcon = (props) => {
  const { complete, attempted, selected, shortTermGoalIndex, mediumTermGoalIndex, lastItem, step, normalRender } = props;
  const isShortTerm = shortTermGoalIndex === step;
  const isMediumTerm = mediumTermGoalIndex === step;
  const Icon = complete ? Check : FilledCircle;
  const borderColor = selected ? '#333' : complete || attempted ? '#666' : '#bbb';
  const backgroundColor = complete ? '#589F1C' : '#ddd';
  const htmlColor = complete ? 'white' : '#ddd';
  return (
    <div style={{
      marginTop: '-2px',
      borderRadius: '50%',
      border: `3px solid ${ borderColor }`,
      backgroundColor: backgroundColor,
      position: 'relative',
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}>
      {!normalRender && (isShortTerm || isMediumTerm || lastItem) ? (
        <ShortTermIcon
          style={{
            position: "absolute",
            fontSize: 48,
            color: selected ? "#333" : borderColor,
            zIndex: 0, // Ensures it appears behind the inner circle
          }}
        />
      ) : null}
      <div
        style={{
          width: 24,
          height: 24,
          borderRadius: "50%",
          backgroundColor: backgroundColor,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 1, // Ensures it appears above Brightness5
        }}
      >
        { Icon ?
          <Icon htmlColor={ htmlColor } style={ { width: '1em', height: '0.8em', paddingTop: '0.1em' } } ></Icon> : null }
      </div>
      { selected &&
      <div style={ { position: 'absolute', left: 0, bottom: -18 } }><DownArrow htmlColor={ borderColor } /></div> }
    </div>
  );
};

const connectorStyles = makeStyles(() => ({
  lineHorizontal: {
    borderTopWidth: '3px',
    borderColor: '#666',
  },
  root: {
    flex: '1 1 auto',
  },
  /* Styles applied to the root element if `alternativeLabel={true}`. */
  /* Nasty default styles copied from the StepConnector Material UI component. */
  alternativeLabel: {
    position: 'absolute',
    top: 8 + 4,
    left: 'calc(-50% + 0.8em)',
    right: 'calc(50% + 0.5em)',
  },
  line: {
    display: 'block',
  },
}));

export const ChallengeConnector = (props) => {
  const classes = connectorStyles();
  return (
    <StepConnector classes={ classes } { ...props } />
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 500,
  },
  centre: {
    margin: 'auto',
  },
  tabPanel: {
    backgroundColor: progressBackgroundColor,
    width: '80%',
    marginBottom: -10,
  },
  stepper: {
    backgroundColor: progressBackgroundColor,
    padding: '0px',
  },
}));

const GameProgress = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [selectedChallenge, setSelectedChallenge] = React.useState({});
  const stepSorting = (a, b) => (a['step'] - b['step']) * 10 + b['attempted'] - a['attempted'];
  const { t } = useTranslation();

  useEffect(() => {
    if (props.progress) {
      const progress = props.progress[value];

      const inGame = (progress && progress.inGame.sort(stepSorting));
      const outOfGame = (progress && progress.outOfGame.sort(stepSorting));
      const inGameActive = findActiveStep(inGame);
      const outOfGameActive = findActiveStep(outOfGame);
      if (inGameActive !== undefined) {
        setSelectedChallenge({ ...inGame[inGameActive], type: 'i', index: inGameActive });
      } else if (outOfGameActive !== undefined) {
        setSelectedChallenge({ ...outOfGame[outOfGameActive], type: 'o', index: outOfGameActive });
      } else {
        setSelectedChallenge({});
      }
    }
  }, [value, props.progress]);

  if (!props.progress) {
    return <div className={ classes.root }>
      <CircularProgress className={ classes.centre } />
    </div>;
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const validXS = function (size) {
    return Math.max(1, Math.min(size, 12));
  };

  return (
    <div className={ classes.root }>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={ value }
        onChange={ handleChange }
        scrollButtons="on"
        aria-label="Vertical tabs"
        TabIndicatorProps={ { style: { width: '100%', backgroundColor: '#000', opacity: 0.035294117647058822 } } }
      >
        { props.progress.map((goal, index) => <Tab component={ TabComponent }
                                                   goalName={ `Mission ${ index + 1 }` } { ...goal } { ...a11yProps(index) }
                                                   key={ index } />) }
      </Tabs>
      { props.progress.map((goal, index) => {
          const inGame = goal.inGame.sort(stepSorting);
          const outOfGame = goal.outOfGame.sort(stepSorting);
          const inGameXS = validXS(inGame.length);
          const outOfGameXS = validXS(outOfGame.length);
          const rightPad = 10 - (inGameXS + outOfGameXS);
          const shortTermGoalIndex = goal.short_term_goal_index;
          const mediumTermGoalIndex = goal.medium_term_goal_index;
          const rightPadXS = rightPad > 0 ? rightPad : undefined;
          return (
            <TabPanel value={ value } index={ index } className={ classes.tabPanel } key={ index }>
              <Grid container>
                <Grid item xs={ 11 }>{ goal.title }</Grid>
                <Grid item xs={ 1 }>
                  <div
                    data-tip={t(translationKey.LabelGameProgressInfo)}
                    data-for='game-progress'
                    style={ { textAlign: 'right' } }>
                    <Info htmlColor={ tooltipIconColor } />
                    <ReactToolTip
                      id='game-progress'
                      getContent={ toolTipContent }
                      type='light'
                      multiline
                    />
                  </div>
                </Grid>
                <Grid item xs={ 2 } />
                <Grid item xs={ inGameXS } style={ { borderRight: '1px solid #ccc' } }>
                  <Typography color='textSecondary' align='center'>{t(translationKey.LabelInGame)}</Typography>
                </Grid>
                <Grid item xs={ outOfGameXS }>
                  <Typography color='textSecondary' align='center'>{t(translationKey.LabelOutOfGame)}</Typography>
                </Grid>
                { rightPadXS && <Grid item xs={ rightPadXS } /> }
                <Grid item xs={ 2 } />
                <Grid item xs={ inGameXS } style={ { marginTop: '12px' } }>
                  <Stepper nonLinear alternativeLabel className={ classes.stepper } connector={ <ChallengeConnector /> }>
                    { inGame.map((challenge, index) => (
                      <Step key={ challenge.name }>
                        <StepButton
                          completed={ challenge.complete }
                          onClick={ () => setSelectedChallenge({ ...challenge, type: 'i', index }) }
                          icon={ <ChallengeIcon { ...challenge }
                                                selected={ selectedChallenge.type === 'i' && selectedChallenge.index === index } normalRender={true}/> } />
                      </Step>
                    )) }
                  </Stepper>
                </Grid>
                <Grid item xs={ outOfGameXS } style={ { marginTop: '12px' } }>
                  <Stepper nonLinear alternativeLabel className={ classes.stepper } connector={ <ChallengeConnector /> }>
                    { outOfGame.map((challenge, index) => (
                      <Step key={ challenge.name }>
                        <StepButton
                          completed={ challenge.complete }
                          onClick={ () => setSelectedChallenge({ ...challenge, type: 'o', index }) }
                          icon={ <ChallengeIcon { ...challenge }
                                                selected={ selectedChallenge.type === 'o' && selectedChallenge.index === index } 
                                                shortTermGoalIndex={shortTermGoalIndex}
                                                mediumTermGoalIndex={mediumTermGoalIndex}
                                                lastItem={outOfGame.length-1 === index}
                                                normalRender={false}
                                  /> } />
                      </Step>
                    )) }
                  </Stepper>
                </Grid>
                { rightPadXS && <Grid item xs={ rightPadXS } /> }
                <Grid item xs={ 2 } />
                <Grid item xs={ 10 }>{ selectedChallenge.name }</Grid>
                <Grid item xs={ 2 }>
                  <Typography color='textSecondary' align='center'>{t(translationKey.LabelAttempted)}</Typography>
                </Grid>
                { inGame.map((challenge, i) => {
                  const fontWeight = (selectedChallenge.type === 'i' && selectedChallenge.index === i) ? 600 : undefined;
                  return (
                    <Grid item xs={ 1 } style={ { textAlign: 'center', fontWeight } }
                          key={ i }>{ challenge.attempted ? challenge.attempted : null }</Grid>
                  );
                }) }
                { outOfGame.map((challenge, i) => {
                  const fontWeight = (selectedChallenge.type === 'o' && selectedChallenge.index === i) ? 600 : undefined;
                  return (
                    <Grid item xs={ 1 } style={ { textAlign: 'center', fontWeight } }
                          key={ i }>{ challenge.attempted ? challenge.attempted : null }</Grid>
                  );
                }) }
                { inGame.length === 0 && <Grid item xs={ 1 } /> }
                { outOfGame.length === 0 && <Grid item xs={ 1 } /> }
                { rightPadXS && <Grid item xs={ rightPadXS } /> }
                <Grid item xs={ 2 } />
                <Grid item xs={ validXS(inGameXS + outOfGameXS) }>
                  <hr />
                </Grid>
                { rightPadXS && <Grid item xs={ rightPadXS } /> }
                <Grid item xs={ 2 }>
                  <Typography color='textSecondary' align='center'>{t(translationKey.LabelCompleted)}</Typography>
                </Grid>
                { inGame.map((challenge, i) => {
                  const fontWeight = (selectedChallenge.type === 'i' && selectedChallenge.index === i) ? 600 : undefined;
                  return (
                    <Grid item xs={ 1 } style={ { textAlign: 'center', fontWeight } }
                          key={ i }>{ challenge.repeated ? challenge.repeated : null }</Grid>
                  );
                }) }
                { outOfGame.map((challenge, i) => {
                  const fontWeight = (selectedChallenge.type === 'o' && selectedChallenge.index === i) ? 600 : undefined;
                  return (
                    <Grid item xs={ 1 } style={ { textAlign: 'center', fontWeight } }
                          key={ i }>{ challenge.repeated ? challenge.repeated : null }</Grid>
                  );
                }) }
                { inGame.length === 0 && <Grid item xs={ 1 } /> }
                { outOfGame.length === 0 && <Grid item xs={ 1 } /> }
                { rightPadXS && <Grid item xs={ rightPadXS } /> }
                <Grid item xs={ 12 }>
                  <SingleChallengeReflections reflections={ selectedChallenge.reflections } />
                </Grid>
              </Grid>
            </TabPanel>
          );
        },
      ) }
    </div>
  );
};

export default GameProgress;
