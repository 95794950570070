import { translationKey } from '../translationKeys';

const translation = {
  [translationKey.AccessibilityLabelADenseTable]: 'a dense table',
  [translationKey.AccessibilityLabelSecondaryCheckbox]: 'secondary checkbox',
  [translationKey.AlertBodyCancelEntityForm]: 'All unsaved data will be lost',
  [translationKey.AlertBodyCancelOrganisationForm]: 'All unsaved data will be lost',
  [translationKey.AlertBodyCancelYoungForm]: 'All unsaved data will be lost',
  [translationKey.AlertBodyConfirmDeleteYoungPerson]: 'Deleting a young person will permanently remove all personal information and anonymise game data.',
  [translationKey.AlertBodyDistributorUploadFail]: 'The upload experienced {{error}}',
  [translationKey.AlertBodyDuplicateOrganisation]: 'The postcode for this organisation already exists. Are you sure you want to submit this Organisation?',
  [translationKey.AlertBodyInviteSent]: 'Invite Sent',
  [translationKey.AlertBodyInvitesSent]: 'Distributors will now have an invitation to create their account',
  [translationKey.AlertBodyPasswordReset]: 'Your password has been reset',
  [translationKey.AlertBodyResendGuardianInvite]: 'Do you want to resend the SMS invite to the guardian?',
  [translationKey.AlertBodyResetLinkSent]: 'If this account exists we\'ll send an email with a password reset link.',
  [translationKey.AlertBodyStartProcessing]: 'We\'ll start collecting the data when you click "Yes", we\'ll send you an email when it is ready for you',
  [translationKey.AlertBodyStartProcessingMHSDS]: 'We\'ll start collecting the data when you click "Yes"',
  [translationKey.AlertBodyUnsavedDataWarning]: 'All unsaved data will be lost',
  [translationKey.AlertIsUserSure]: 'Are you sure to do this?',
  [translationKey.AlertNoFile]: 'No file attached',
  [translationKey.AlertTitleAdminInvite]: 'Admin Invite',
  [translationKey.AlertTitleCancelAdminInvite]: 'Cancel Admin Invitation',
  [translationKey.AlertTitleCancelEntityForm]: 'Cancel Entity Form',
  [translationKey.AlertTitleCancelOrganisationForm]: 'Cancel Organisation Form',
  [translationKey.AlertTitleCancelYoungForm]: 'Cancel Young Person Form',
  [translationKey.AlertTitleConfirmDeleteYoungPerson]: 'Are you sure you want to delete young person?',
  [translationKey.AlertTitleDeactivationReason]: 'Pick a deactivation reason',
  [translationKey.AlertTitleDistributorUploadFail]: 'Distributors Upload Failed',
  [translationKey.AlertTitleDuplicateOrganisation]: 'Duplicate Organisation Warning',
  [translationKey.AlertTitleInvitesSent]: 'Invites Sent',
  [translationKey.AlertTitleResendGuardianInvite]: 'Resend invite to {{ firstname }} {{lastname }}',
  [translationKey.AlertTitleStartProcessing]: 'Start processing',
  [translationKey.AlertTitleSuccess]: 'Success',
  [translationKey.AltGameLogo]: '{{game}} logo',
  [translationKey.AltUserUploadedLogo]: 'User uploaded logo',
  [translationKey.ButtonCancel]: 'Cancel',
  [translationKey.ButtonCheckMyEligibility]: 'Check my eligibility',
  [translationKey.ButtonClose]: 'Close',
  [translationKey.ButtonCompletedSurveys]: 'completed surveys',
  [translationKey.ButtonCreateDistributor]: 'Create Distributor',
  [translationKey.ButtonCreateEntity]: 'Create Entity',
  [translationKey.ButtonCreateOrganisation]: 'Create Organisation',
  [translationKey.ButtonDownloadFile]: 'Download File',
  [translationKey.ButtonDownloadReport]: 'DOWNLOAD REPORT',
  [translationKey.ButtonGetUpdatedData]: 'Get Updated Data',
  [translationKey.ButtonGetHubUsageData]: 'Get Hub Usage Data',
  [translationKey.ButtonGoalBasedOutcomes]: 'Goal Based Outcomes and Guardian Survey answers (detailed view)',
  [translationKey.ButtonHideOrViewCompletedSurveys]: '{{option}} COMPLETED SURVEYS',
  [translationKey.ButtonHide]: 'hide',
  [translationKey.ButtonInviteNewUser]: 'Invite New User',
  [translationKey.ButtonInviteYoungPerson]: 'INVITE YOUNG PERSON',
  [translationKey.ButtonLogoutUser]: 'Logout {{user}}',
  [translationKey.ButtonResendInvite]: 'RESEND INVITE',
  [translationKey.ButtonSave]: 'save',
  [translationKey.ButtonSendInvites]: 'Send invites',
  [translationKey.ButtonShow]: 'show',
  [translationKey.ButtonSignIn]: 'Sign in',
  [translationKey.ButtonSubmit]: 'Submit',
  [translationKey.ButtonRegister]: 'Register',
  [translationKey.ButtonTitleBack]: 'Back',
  [translationKey.ButtonTitleSendInvite]: 'SEND INVITE',
  [translationKey.ButtonUploadFile]: 'Upload File',
  [translationKey.ChoiceLabelFemale]: 'Female',
  [translationKey.ChoiceLabelMale]: 'Male',
  [translationKey.ChoiceLabelNA]: 'Prefer not to say',
  [translationKey.ChoiceLabelNo]: 'No',
  [translationKey.ChoiceLabelNone]: 'None',
  [translationKey.ChoiceLabelYes]: 'Yes',
  [translationKey.ComponentGameTimeAverage]: '<0>{{minutes}}</0><1>mins</1>',
  [translationKey.ConfirmRestoreName]: 'Confirm to restore {{name}}',
  [translationKey.ConfirmationOk]: 'Ok',
  [translationKey.Confirm]: 'Confirm',
  [translationKey.DataTipReflectionInfo]: 'reflections info',
  [translationKey.DateFormat]: 'DD/MM/YYYY',
  [translationKey.DayMonday]: 'Monday',
  [translationKey.DayMondayShort]: 'Mon',
  [translationKey.DayTuesday]: 'Tuesday',
  [translationKey.DayTuesdayShort]: 'Tues',
  [translationKey.DayWednesday]: 'Wednesday',
  [translationKey.DayWednesdayShort]: 'Wed',
  [translationKey.DayThursday]: 'Thursday',
  [translationKey.DayThursdayShort]: 'Thurs',
  [translationKey.DayFriday]: 'Friday',
  [translationKey.DayFridayShort]: 'Fri',
  [translationKey.DaySaturday]: 'Saturday',
  [translationKey.DaySaturdayShort]: 'Sat',
  [translationKey.DaySunday]: 'Sunday',
  [translationKey.DaySundayShort]: 'Sun',
  [translationKey.DOBFieldMessage]: 'Select a date 5 years or older',
  [translationKey.ErrorApologyForProblem]: 'Sorry, there\'s been a problem.',
  [translationKey.ErrorConfirmPassword]: 'Please confirm your password',
  [translationKey.ErrorDistributorMustBeSelected]: 'A distributor must be selected',
  [translationKey.ErrorEnterPassword]: 'Please enter a password',
  [translationKey.ErrorGameKeysSpecifiedMustBePositive]: 'You must specify a positive number of game keys',
  [translationKey.ErrorInvalidEmail]: 'Invalid email address',
  [translationKey.ErrorLogoRequired]: 'Logo is required',
  [translationKey.ErrorMinimumPassword]: 'Minimum password length: {{ min_length }} characters',
  [translationKey.ErrorMissingEmail]: 'Missing email',
  [translationKey.ErrorMissingName]: 'Missing name',
  [translationKey.ErrorMissingPhoneNumber]: 'Missing phone number',
  [translationKey.ErrorMissingRelationship]: 'Missing relationship',
  [translationKey.ErrorNoGameAccess]: 'You do not have access to any games',
  [translationKey.ErrorPasswordMatch]: 'Passwords don\'t match',
  [translationKey.ErrorPasswordRequired]: 'Password Required',
  [translationKey.ErrorRequired]: 'Required',
  [translationKey.ErrorSpecifyAddress]: 'Please specify an address',
  [translationKey.ErrorSpecifyBuyerName]: 'Please specify the name of the buyer',
  [translationKey.ErrorSpecifyDOB]: 'Please specify date of birth (young person must be at least 5 years old)',
  [translationKey.ErrorSpecifyGender]: 'Please specify gender',
  [translationKey.ErrorSpecifyEthnicity]: 'Please specify ethnicity',
  [translationKey.ErrorSpecifyDisability]: 'Please specify disability',
  [translationKey.ErrorSpecifyDisabilityTypes]: 'Please select one or more options',
  [translationKey.ErrorSpecifyDistributorAddress]: 'Please specify the distributor\'s address',
  [translationKey.ErrorSpecifyDistributorName]: 'Please specify the distributor name',
  [translationKey.ErrorSpecifyEntityName]: 'Please specify the entity name',
  [translationKey.ErrorSpecifyFirstName]: 'Please specify the first name',
  [translationKey.ErrorSpecifyGameKeysAllocatedToEntity]: 'Please specify how many game keys are allocated to the entity for at least one intervention',
  [translationKey.ErrorSpecifyGuardianFirstName]: 'Please specify Guardian\'s First Name',
  [translationKey.ErrorSpecifyGuardianLastName]: 'Please specify Guardian\'s Last Name',
  [translationKey.ErrorSpecifyMarketingVisibilityResponse]: 'Please specify how you heard of us',
  [translationKey.ErrorSpecifyLastName]: 'Please specify the last name',
  [translationKey.ErrorSpecifyNumber]: 'Please specify number',
  [translationKey.ErrorSpecifyOrganisationAddress]: 'Please specify the organisation address',
  [translationKey.ErrorSpecifyOrganisationGameKeys]: 'Please specify how many game keys the organisation has purchased',
  [translationKey.ErrorSpecifyOrganisationName]: 'Please specify the organisation name',
  [translationKey.ErrorSpecifyOrganisationPostcode]: 'Please specify the organisation postcode',
  [translationKey.ErrorSpecifyPhoneNumber]: 'Please specify a phone number',
  [translationKey.ErrorSpecifyPointPersonEmail]: 'Please specify a point person email',
  [translationKey.ErrorSpecifyPointPersonNameOrg]: 'Please specify the name of the point person',
  [translationKey.ErrorSpecifyPointPersonName]: 'Please specify point person name',
  [translationKey.ErrorSpecifyPointPersonPhone]: 'Please specify a point person phone',
  [translationKey.ErrorSpecifyRelationship]: 'Please specify Relationship',
  [translationKey.ErrorSuggestedBrowsers]: 'For the best experience, we recommend you use Chrome, Firefox, Safari or Microsoft Edge.',
  [translationKey.ErrorUnknown]: 'An unknown error',
  [translationKey.ErrorUnsupportedBrowser]: 'Oops! Looks like you’re using a browser we don\'t support!',
  [translationKey.ErrorUrlIncomplete]: 'URL incomplete',
  [translationKey.ErrorValidPhoneNumber]: 'Please enter a valid number (with international dialling code if not +44)',
  [translationKey.FaqAnswerContact]: 'For any other questions please email support@bfb-labs.com',
  [translationKey.FaqAnswerForgotGameKey]: 'A young person will need their game key once to unlock Champions of the Shengha Pro after downloading the game to their device. If they forget/lose their game key, they may be prompted to contact you to request it. You can provide the user key to them from their young person’s profile on your Young People list.',
  [translationKey.FaqAnswerHowDoIChooseGamePlayersCots]: '<span> Champions of the Shengha Pro is intended for: </span> <ul> <li> 9-18 year olds </li> <li> Any gender </li> <li> Can benefit from learning emotional self-regulation skills to better deal with stress, anxiety and frustration </li> <li> Can be used solely or alongside other treatments </li> </ul>',
  [translationKey.FaqAnswerHowDoIChooseGamePlayersLn]: '<span> Lumi Nova is intended for: </span> <ul> <li> Ages 7-12 </li> <li> Any gender </li> <li> May already be seeking treatment </li> <li> Mild to moderate anxiety </li> </ul> <span> Lumi Nova is NOT intended for young people fast-tracked to treatment and better managed by highly-experienced clinicians: </span> <ul> <li> High-risk cases </li> <li> Generalised anxiety </li> <li> Panic disorder </li> </ul>',
  [translationKey.FaqAnswerHowToIntroduceAGuardian]: '<ul> <li> Some parents or guardians may be very aware of their child’s anxiety, and others may not, especially if it is specific to school activities </li> <li> Some guardians may have their own anxieties as well. </li> <li> It’s important not to make guardians feel like their child is being singled out, or as if they are to blame for their child’s anxiety. </li> <li> Explain a bit about the game and the opportunity for their child to build confidence, learn to cope with worries, and become more resilient. </li> <li> If they agree to their child’s participation, let them know to expect an SMS with a link to a brief introduction to the game and a short survey about their child’s anxiety. </li> </ul>',
  [translationKey.FaqAnswerHowToSendGameInfoToYoungPerson]: '<span> Saving a new Young Person profile in the VitaMind Hub will automatically send an SMS to the mobile number provided with the game key and download link to the game. </span> <br/> <span> You will also need to provide a young person with their own Champions of the Shengha game pack. </span>',
  [translationKey.FaqAnswerIfGuardianForgetsGameKey]: 'Guardians will need their game key to verify the completion of their young person’s out of game challenges. If they forget their game key, they may be prompted to contact you to request it. You can provide the user key to them from their young person’s profile on your Young People list.',
  [translationKey.FaqAnswerIntroduceGameToAYoungPersonCots]: 'Make it fun! Tell them they can play a new mobile game to help them master their mind and body and describe how the game works: ”Champions of the Shengha is a fun game with a difference: you control the game with your body and mind. A Magic Transmitter measures your magic power and sends it to the game. To succeed in the Shengha Temple, you need to stay focused and calm under pressure.”',
  [translationKey.FaqAnswerIntroduceGameToAYoungPersonLn]: '<span> It is important not to make a young person feel like they’re being singled out, as this may add to any anxiety they’re already experiencing. </span> <br/> <span> Some guardians may prefer to introduce the game to their child themselves, so check in with guardians first. If they do want you to talk to the young person about the game, keep these tips in mind: </span> <ul> <li> Normalise anxiety - tell them that everyone worries sometimes! </li> <li> Ask open questions about some of the things they worry about - What do you worry about at home, at school, etc.? </li> <li> Make it fun! Tell them they can play a fun new game to help them learn how to build confidence and cope with their worries! </li> <li> Describe how the game works - they’ll get to choose a goal that may be hard for them to do now, and the game will help them break it down into really small steps so they feel less worried along the way. </li> </ul>',
  [translationKey.FaqAnswerMissedTrainingCots]: '<span>Training with providers from BfB Labs is a key component of successfully supporting young people and a great opportunity for you to connect with other teachers and clinicians in your area. If you were unable to attend: </span> <ul> <li>Contact BfB Labs at support@bfb-labs.com about joining another training session</li> <li>Review the training materials provided <lnk to=\'/cots/resources\'>here</lnk> </li> </ul>',
  [translationKey.FaqAnswerMissedTrainingLn]: '<span>Training with providers from BfB Labs is a key component of successfully supporting young people and a great opportunity for you to connect with other teachers and clinicians in your area. If you were unable to attend: </span> <ul> <li>Contact BfB Labs at support@bfb-labs.com about joining another training session</li> <li>Review the training materials provided <lnk to=\'/ln/resources\'>here</lnk> </li> </ul>',
  [translationKey.FaqAnswerOngoingSupport]: '<span> Guardian support is a critical component of success for Lumi Nova. Whenever possible, you might consider encouraging their continuing involvement in some of these ways: </span> <ul> <li> Check in with your game players and encourage them to tell their guardians about what they’re doing in the game </li> <li> Send email reminders to suggest that guardians check in with their young person about their progress in the game </li> <li> Organise school / organisation-wide coffee mornings or meetings for guardians to talk with you and with each other about their experiences, progress, and concerns with their young people playing Lumi Nova </li> </ul>',
  [translationKey.FaqAnswerReviewTrainingCots]: 'Yes, you can download all training materials and more in the <lnk to=\'/cots/resources\'>Resources</lnk> section of this site.',
  [translationKey.FaqAnswerReviewTrainingLn]: 'Access your training pack <lnk to=\'/ln/resources\'>here</lnk>',
  [translationKey.FaqAnswerRunOutOfLicenses]: 'When you only have a few licenses remaining, BfB Labs will automatically be notified and will arrange to provide you with more licenses if necessary. If you have any questions, you can contact BfB Labs at support@bfb-labs.com',
  [translationKey.FaqAnswerSignsOfAnxiety]: '<ul> <li> Anxious thoughts - expecting bad things to happen </li> <li> Anxious feelings - rapid heart rate, fast breathing, sweating, tummy aches, headaches </li> <li> Anxious behaviours - fight, flight, or safety-seeking </li> </ul>',
  [translationKey.FaqAnswerWhatIsAnxiety]: '<span> Lumi Nova supports young people who show signs of anxiety that prevents them from doing things other people their age commonly do. These include: </span> <ul> <li> Separation anxiety - fear of being away from a particular person, usually a parent or carer, that may make it difficult for a young person to sleep alone, be home with other adults, sleep away from home, or attend school </li> <li> Social anxiety - fear of being in the spotlight, being judged, rejected, or embarrassed, that may make it difficult for a young person to take tests, speak in front of others, make mistakes, make new friends, be around strangers, attend social events, or attend school </li> <li> Specific phobias - fear of concrete objects that may make it difficult for young people to be around insects, dogs, visit high up places, or spend time in the dark </li> </ul>',
  [translationKey.FaqAnswerWhenCanISeeResults]: 'You can expect to start seeing results once a young person starts playing the game.',
  [translationKey.FaqQuestionContact]: 'Contact',
  [translationKey.FaqQuestionForgotGameKey]: 'What if a young person forgets their game keys?',
  [translationKey.FaqQuestionHowDoIChooseGamePlayers]: 'How do I choose game players?',
  [translationKey.FaqQuestionHowToIntroduceAGuardian]: 'How do I introduce the game to a new guardian?',
  [translationKey.FaqQuestionHowToSendGameInfoToYoungPerson]: 'How do I send game information to a young person?',
  [translationKey.FaqQuestionIfGuardianForgetsGameKey]: 'What if a guardian forgets their game keys?',
  [translationKey.FaqQuestionIntroduceGameToAYoungPerson]: 'How do I introduce the game to a new young person?',
  [translationKey.FaqQuestionMissedTraining]: 'What if I didn\'t attend face-to-face training?',
  [translationKey.FaqQuestionOngoingSupport]: 'How do I provide ongoing support to game players and guardians?',
  [translationKey.FaqQuestionReviewTraining]: 'Can I review training materials after the session?',
  [translationKey.FaqQuestionRunOutOfLicenses]: 'What if I run out of licenses?',
  [translationKey.FaqQuestionSendInformationToGuardians]: 'How do I send game information to guardians?',
  [translationKey.FaqQuestionSignsOfAnxiety]: 'What are the common signs of anxiety?',
  [translationKey.FaqQuestionWhatIsAnxiety]: 'What is mild to moderate anxiety?',
  [translationKey.FaqQuestionWhenCanISeeResults]: 'When can I expect to see results?',
  [translationKey.HoursAndMinutesComponent]: '<0>{{hours}}</0><1>hrs</1> <0>{{minutes}}</0><1>mins</1>',
  [translationKey.Label2FATextField]: 'Authentication code',
  [translationKey.Label2FAButton]: 'Continue',
  [translationKey.Label2FAEmailSent]: 'Email Sent!',
  [translationKey.Label2FAResend]: 'Resend email',
  [translationKey.Label2FAResendHelp]: 'Did not receive the code? ',
  [translationKey.LabelAccountOfCurrentUser]: 'account of current user',
  [translationKey.LabelActivePlayersThisWeek]: 'Active players this week',
  [translationKey.LabelActiveUntil]: 'Active until {{-date}}',
  [translationKey.LabelActive]: 'Active',
  [translationKey.LabelAddress]: 'Address',
  [translationKey.LabelAlreadyHaveAnAccount]: 'Already have an account?',
  [translationKey.LabelAlways]: 'Always',
  [translationKey.LabelAssignSelfSignUpDistributor]: 'Assign Self Sign Up Distributor',
  [translationKey.LabelAssignedKeys]: 'Assigned Game Keys',
  [translationKey.LabelAttempted]: 'Attempted',
  [translationKey.LabelAverageSessionTime]: 'Average time per session',
  [translationKey.LabelAxisMonth]: 'Month',
  [translationKey.LabelBaseline]: 'Baseline - ',
  [translationKey.LabelBeforeChallenge]: 'Before challenge',
  [translationKey.LabelBuyerEmail]: 'Buyer Email',
  [translationKey.LabelBuyerName]: 'Buyer Name',
  [translationKey.LabelCancel]: 'Cancel',
  [translationKey.LabelChallenge]: 'Challenge',
  [translationKey.LabelChallengesCompleted]: 'Challenges completed',
  [translationKey.LabelChampionsOfTheShengha]: 'Champions of the Shengha Pro',
  [translationKey.LabelCheckYourPostcode]: 'Check your postcode',
  [translationKey.LabelChildCorsCompletedOn]: 'Child Outcome Rating Scale (CORS) - Completed On',
  [translationKey.LabelClickToDownloadResources]: 'Click to download any of our training, support and marketing resources.',
  [translationKey.LabelColonAddress]: 'Address:',
  [translationKey.LabelColonEmail]: 'Email:',
  [translationKey.LabelColonName]: 'Name:',
  [translationKey.LabelColonPhone]: 'Phone:',
  [translationKey.LabelConfirmPassword]: 'Confirm Password',
  [translationKey.LabelConfirmSendSms]: 'Click \'SEND INVITE\' to send the SMS and save the profile or go back to make changes.',
  [translationKey.LabelContact]: 'CONTACT',
  [translationKey.LabelContactUs]: 'If you would like any help with this form, please ',
  [translationKey.LabelCorsBaselineCompletedOn]: 'CORS - Baseline completed on {{date}} ',
  [translationKey.LabelCorsCompletedOn]: 'CORS - completed on {{date}}',
  [translationKey.LabelCotsGameKeys]: 'Champions of the Shengha Game Keys',
  [translationKey.LabelCotsProLicensesValidUntil]: 'COTS Pro Licenses Valid Until',
  [translationKey.LabelCotsProLicenses]: 'COTS Pro Licenses',
  [translationKey.LabelCountry]: 'Country',
  [translationKey.AlertTitleDistributorsUploaded]: 'Distributors Uploaded',
  [translationKey.LabelCreateYoungProfile]: 'Create a young person\'s profile',
  [translationKey.LabelCurrentMonth]: 'Current Month ({{month}})',
  [translationKey.LabelCustomSupportInfo]: 'Custom Support Info',
  [translationKey.LabelCustomTitle]: 'Custom Title',
  [translationKey.LabelCustomizeSignUpPageUrl]: 'Customise Sign Up Page URL',
  [translationKey.LabelDateChallengeAttempted]: 'Date challenge attempted',
  [translationKey.LabelDate]: 'date',
  [translationKey.DistributorWeeklyEmailSettingsEnableEmail]: 'Enable weekly activity email',
  [translationKey.DistributorWeeklyEmailSettingsReportTime]: 'Report Time',
  [translationKey.DistributorWeeklyEmailSettingsReportDay]: 'Report Day',
  [translationKey.LabelDay]: 'day',
  [translationKey.LabelDelete]: 'Delete',
  [translationKey.LabelDisabilityEnquiry]: 'Does the young person have special educational needs and/or disabilities?',
  [translationKey.LabelDisabilityEnquiryTypes]: 'Do you feel the young person has any of the following?',
  [translationKey.LabelDisabilityLearningDisability]: 'Learning Disability',
  [translationKey.LabelDisabilityADHD]: 'ADHD',
  [translationKey.LabelDisabilityAutism]: 'Autism',
  [translationKey.LabelDisabilityPhysicalDisability]: 'Physical Disability',
  [translationKey.LabelDisabilityOther]: 'Other',
  [translationKey.LabelDisabilityPreferNotToSay]: 'Prefer not to say',
  [translationKey.LabelDistributorAddress]: 'Distributor Address',
  [translationKey.LabelDistributorEmail]: 'Distributor Email',
  [translationKey.LabelDistributorFirstName]: 'Distributor First Name',
  [translationKey.LabelDistributorLastName]: 'Distributor Last Name',
  [translationKey.LabelDistributorName]: 'Distributor Name',
  [translationKey.LabelDistributorPhone]: 'Distributor Phone Number',
  [translationKey.LabelDownloadDistributors]: 'Download Distributors',
  [translationKey.LabelDownloadAllYoungPeople]: 'Download all young people (.csv)',
  [translationKey.LabelDownloadYoungPeople]: 'Download selected tab only (.csv)',
  [translationKey.LabelDuels]: 'Duels',
  [translationKey.LabelDuringChallenge]: 'During challenge',
  [translationKey.LabelEditYoungProfile]: 'Edit the young person\'s profile',
  [translationKey.LabelEmailAddress]: 'Email Address',
  [translationKey.LabelEmail]: 'Email',
  [translationKey.LabelEnableCustomSupportInfo]: 'Enable Custom Support Info',
  [translationKey.LabelEnableSelfSignUp]: 'Enable Self Sign Up Page',
  [translationKey.LabelEnableWebhook]: 'Enable Webhook',
  [translationKey.LabelEnterYourPostcode]: 'Please enter your postcode below to check if Lumi Nova is available in your area',
  [translationKey.LabelEntityAddress]: 'Entity Address',
  [translationKey.LabelEntityLogo]: 'Entity Logo',
  [translationKey.LabelEntityName]: 'Entity Name',

  [translationKey.LabelEthnicityArab]: 'Arab',
  [translationKey.LabelEthnicityAsianBritish]: 'Asian/Asian British',
  [translationKey.LabelEthnicityBlackEthnicities]: 'Black/African/Caribbean/Black British',
  [translationKey.LabelEthnicityChinese]: 'Chinese',
  [translationKey.LabelEthnicityMultipleEthnicGroups]: 'Mixed/Multiple ethnic groups',
  [translationKey.LabelEthnicityOtherEthnicGroup]: 'Other ethnic group',
  [translationKey.LabelEthnicityWhite]: 'White',
  [translationKey.LabelEthnicityWhiteBritish]: 'White - British',
  [translationKey.LabelEthnicityWhiteIrish]: 'White - Irish',
  [translationKey.LabelEthnicityWhiteOther]: 'White - Any other White background',
  [translationKey.LabelEthnicityMixedWhiteAndCaribbean]: 'Mixed - White and Black Caribbean',
  [translationKey.LabelEthnicityMixedWhiteAndAfrican]: 'Mixed - White and Black African',
  [translationKey.LabelEthnicityMixedWhiteAndAsian]: 'Mixed - White and Asian',
  [translationKey.LabelEthnicityMixedOther]: 'Mixed - Any other mixed background',
  [translationKey.LabelEthnicityAsianIndian]: 'Asian or Asian British - Indian',
  [translationKey.LabelEthnicityAsianPakistani]: 'Asian or Asian British - Pakistani',
  [translationKey.LabelEthnicityAsianBangladeshi]: 'Asian or Asian British - Bangladeshi',
  [translationKey.LabelEthnicityAsianOther]: 'Asian or Asian British - Any other Asian background',
  [translationKey.LabelEthnicityBlackCaribbean]: 'Black or Black British - Caribbean',
  [translationKey.LLabelEthnicityBlackAfrican]: 'Black or Black British - African',
  [translationKey.LLabelEthnicityBlackOther]: 'Black or Black British - Any other Black background',
  [translationKey.LabelEthnicityOtherChinese]: 'Other Ethnic Groups - Chinese',
  [translationKey.LabelEthnicityOther]: 'Any other ethnic group',
  [translationKey.LabelEthnicity]: 'Ethnicity',
  [translationKey.LabelExposureChallengeReflections]: 'Exposure challenge reflections',
  [translationKey.LabelFaqFindEverythingYouNeedCots]: 'Find everything you need to distribute the game and support young people.',
  [translationKey.LabelFaqFindEverythingYouNeedLn]: 'Find everything you need to distribute the game and support young people and their guardians.',
  [translationKey.LabelFemale]: 'Female',
  [translationKey.LabelFilterBy]: 'Filter by:',
  [translationKey.LabelFinalScore]: 'finalScore',
  [translationKey.LabelForMoreInformationContact]: 'For more information or support please contact:',
  [translationKey.LabelGameProgressInfo]: 'game progress info',
  [translationKey.LabelGameSessionTimeInHours]: 'Session time (hours)',
  [translationKey.LabelGameSessionTimeInMins]: 'Game session time (mins)',
  [translationKey.LabelGboScore]: 'GBO Score',
  [translationKey.LabelGender]: 'Gender',
  [translationKey.LabelGoalName]: 'Goal name',
  [translationKey.LabelGoalStartedOn]: 'Started on',
  [translationKey.LabelGoalProgressEndedOn]: 'Goal progress was ended on: ',
  [translationKey.LabelGuardianEmail]: 'Guardian Email Address',
  [translationKey.LabelGuardianFirstName]: 'Guardian First Name',
  [translationKey.LabelGuardianLastName]: 'Guardian Last Name',
  [translationKey.LabelGuardianMobile]: 'Guardian Mobile Number',
  [translationKey.LabelGuardianPostcode]: 'Guardian Postcode (if different from Young Person)',
  [translationKey.LabelGuardianSurveyScasSCore]: 'Guardian Survey SCAS Score',
  [translationKey.LabelHoursAndMinutes]: '{{hours}}h {{mins}}m',
  [translationKey.LabelHours]: 'hrs',
  [translationKey.LabelHrvInMs]: 'HRV (in milliseconds)',
  [translationKey.LabelIfAskedToDoItAgain]: 'If asked to do it again',
  [translationKey.LabelImproving]: 'Improving',
  [translationKey.LabelInGame]: 'In game',
  [translationKey.LabelInstructionsForUse]: 'Instructions for Use',
  [translationKey.LabelInsufficientData]: 'Insufficient Data',
  [translationKey.LabelInviteYoungPerson]: 'Invite a young person to get started',
  [translationKey.LabelInvitesSent]: 'Invites sent',
  [translationKey.LabelInvitesYouHaveSent]: 'Invites you\'ve sent',
  [translationKey.LabelLastLoggedIn]: 'Last logged in',
  [translationKey.LabelLearnMore]: 'Learn more:',
  [translationKey.LabelLicencesAllocated]: 'Licences Allocated',
  [translationKey.LabelLicenseExpiryDate]: 'License Expiry Date',
  [translationKey.LabelLicensesProvidedBy]: 'Licenses provided by {{name}}',
  [translationKey.LabelLicensesUsedByYou]: 'Licenses used by you',
  [translationKey.LabelLicensesUsed]: '{{used}} out of {{total}}',
  [translationKey.LabelLogInHere]: 'Log in here',
  [translationKey.LabelLumiNovaGameKeys]: 'Lumi Nova Game Keys',
  [translationKey.LabelLumiNovaLicensesValidUntil]: 'Lumi Nova Licenses Valid Until',
  [translationKey.LabelMarketingVisibility]: 'Where did you hear about us?',
  [translationKey.LabelMarketingVisibilityAdvertisement]: 'Advertisement',
  [translationKey.LabelMarketingVisibilityCharityCommunity]: 'Charity / Community Organisation',
  [translationKey.LabelMarketingVisibilityGpHealthcare]: 'GP / Healthcare',
  [translationKey.LabelMarketingVisibilityMentalHealthPro]: 'Mental Health Professional',
  [translationKey.LabelMarketingVisibilityNewsMedia]: 'News / Media Article',
  [translationKey.LabelMarketingVisibilitySchool]: 'School',
  [translationKey.LabelMarketingVisibilitySearchEngine]: 'Search Engine e.g. Google',
  [translationKey.LabelMarketingVisibilitySocialMedia]: 'Social Media',
  [translationKey.LabelMarketingVisibilityFamilyFriend]: 'Family or Friend',
  [translationKey.LabelMarketingVisibilityOther]: 'Other',
  [translationKey.LabelLumiNovaLicenses]: 'Lumi Nova Licenses',
  [translationKey.LabelLumiNova]: 'Lumi Nova',
  [translationKey.LabelMale]: 'Male',
  [translationKey.LabelMeanOfMean]: 'Mean of Mean',
  [translationKey.LabelMeanOfMedian]: 'Mean of Median',
  [translationKey.LabelMean]: 'Mean',
  [translationKey.LabelMedianOfMean]: 'Median of Mean',
  [translationKey.LabelMedianOfMedian]: 'Median of Median',
  [translationKey.LabelMedian]: 'Median',
  [translationKey.LabelMenu]: 'menu',
  [translationKey.LabelMHSDSData]: 'MHSDS Data',
  [translationKey.LabelMHSDSSettings]: 'MHSDS Settings',
  [translationKey.LabelMins]: 'mins',
  [translationKey.LabelMinutes]: '{{mins}}m',
  [translationKey.LabelMobileNumber]: 'Mobile Number',
  [translationKey.LabelMobile]: 'Mobile',
  [translationKey.LabelMonth]: 'month',
  [translationKey.LabelNA]: 'Prefer not to say',
  [translationKey.LabelName]: 'Name',
  [translationKey.LabelNever]: 'Never',
  [translationKey.LabelNextSteps]: 'Next Steps',
  [translationKey.LabelNonBinary]: 'Non-binary',
  [translationKey.LabelNone]: 'None',
  [translationKey.LabelNotImproving]: 'Not Improving',
  [translationKey.LabelNumberOfYoungPeoplePlaying]: 'Number of young people playing the game',
  [translationKey.LabelOften]: 'Often',
  [translationKey.LabelOk]: 'OK',
  [translationKey.LabelOrganisationAddress]: 'Organisation Address',
  [translationKey.LabelOrganisationEnableMHSDSForAdminDownload]: 'Include data in BFB MHSDS for submission',
  [translationKey.LabelOrganisationEnableMHSDS]: 'Enable Org Admin MHSDS download',
  [translationKey.LabelOrganisationName]: 'Organisation Name',
  [translationKey.LabelOrganisationODSCode]: 'ODS Code',
  [translationKey.LabelOrganisationOrgIdComm]: 'OrgIdComm',
  [translationKey.LabelOrganisationPhoneNumber]: 'Organisation Phone Number',
  [translationKey.LabelOrganisationPostcode]: 'Organisation Postcode',
  [translationKey.LabelOrganisationSettings]: '{{ organisation_name }} Settings',
  [translationKey.LabelOther]: 'Other',
  [translationKey.LabelOtherNotListed]: 'Other (not listed)',
  [translationKey.LabelOutOfGame]: 'Out of game',
  [translationKey.LabelParentGuardianInformation]: 'Parent/Guardian Information',
  [translationKey.LabelParent]: 'Parent',
  [translationKey.LabelPassword]: 'Password',
  [translationKey.LabelPerformanceOfAllYoungPeopleOverFirst20]: 'Performance of all young people over their first 20 duels using game metric (BfB Labs)',
  [translationKey.LabelPerformanceOfAllYoungPeopleOverLast20]: 'Performance of all young people over their last 20 duels using game metric (BfB Labs)',
  [translationKey.LabelPerformanceOverFirst20]: 'Performance over first 20 duels using game metric (Bfb Labs)',
  [translationKey.LabelPerformanceOverLast20]: 'Performance over last 20 duels using game metric (Bfb Labs)',
  [translationKey.LabelPhone]: 'Phone',
  [translationKey.LabelPlayerPerformance]: 'Player Performance',
  [translationKey.LabelPlayersBestTime]: '{{player}}\'s best time',
  [translationKey.LabelPlayersGameUsage]: '{{player}}\'s {{timeframe}} game usage',
  [translationKey.LabelPleaseVisitOfficialLumiNova]: 'Please visit the official <0>Lumi Nova website</0> to learn more about supporting your child with Lumi Nova.',
  [translationKey.LabelPointPersonEmail]: 'Point Person Email',
  [translationKey.LabelPointPersonName]: 'Point Person Name',
  [translationKey.LabelPointPersonPhone]: 'Point Person Phone',
  [translationKey.LabelPostcode]: 'Postcode',
  [translationKey.LabelPreviousMonth]: 'Previous Month ({{month}})',
  [translationKey.LabelPrivacyPolicy]: 'Privacy Notice',
  [translationKey.LabelPrivacyPolicySelfSignup]: 'Learn more about how we look after your data in our ',
  [translationKey.LabelProvidePhoneNumberForGameProgress]: 'Please provide your mobile number. We\'ll use this to send you an SMS to get started and notify you about game play progress.',
  [translationKey.LabelReasonColon]: 'Reason: ',
  [translationKey.LabelReason]: 'reason',
  [translationKey.LabelRelationshipToYoungPerson]: 'Relationship to Young Person *',
  [translationKey.LabelRelationship]: 'relationship',
  [translationKey.LabelRepeated]: 'Repeated',
  [translationKey.LabelResponse]: 'response',
  [translationKey.LabelScaleTenExplanation]: '10 means very close',
  [translationKey.LabelScaleZeroExplanation]: '0 means not close',
  [translationKey.LabelScore]: 'score',
  [translationKey.LabelSecondaryCheckbox]: 'secondary checkbox',
  [translationKey.LabelSelectEllipses]: 'Select...',
  [translationKey.LabelSelfSignUpSettings]: 'Self Sign Up Page Settings',
  [translationKey.LabelServiceUserRefDetailed]: 'Service User Ref: {{ref}}',
  [translationKey.LabelServiceUserReferenceField]: 'Service User Reference Field',
  [translationKey.LabelServiceUserReference]: 'Service User Reference',
  [translationKey.LabelShowServiceUserReference]: 'Show Service User Reference',
  [translationKey.LabelCustomEntryOne]: 'Custom Entry 1',
  [translationKey.LabelShowCustomEntryOne]: 'Show Custom Entry One',
  [translationKey.LabelCustomEntryTwo]: 'Custom Entry 2',
  [translationKey.LabelShowCustomEntryTwo]: 'Show Custom Entry Two',
  [translationKey.LabelCustomEntryThree]: 'Custom Entry 3',
  [translationKey.LabelShowCustomEntryThree]: 'Show Custom Entry Three',
  [translationKey.LabelMarketingVisibilityCheckboxTitle]: 'Enable Dropdown Select',
  [translationKey.LabelMarketingVisibilityRow]: 'Lumi Nova Marketing Visibility',
  [translationKey.LabelSelfSignupDisabled]: 'Self signup is not enabled for this entity',
  [translationKey.LabelSmsToGuardian]: 'An automatic SMS will be sent to the guardian\'s mobile number: {{number}}',
  [translationKey.LabelSMSDataNextSMS]: 'Next SMS reminder scheduled on: {{ next_sms_date }}',
  [translationKey.LabelSMSDataPreviousSMS]: 'Last SMS reminder sent on: {{ previous_sms_date }}',
  [translationKey.LabelSMSDataResendCORSSMS]: 'RESEND CORS SMS TO PARENT/GUARDIAN',
  [translationKey.LabelSMSDataCORSURL]: 'CORS URL: ',
  [translationKey.LabelSMSDataResendCORSSMSMessage]: 'Click CONFIRM to resend an SMS to {{ guardian_name }} to remind them to complete their weekly CORS survey',
  [translationKey.LabelSMSDataResendCORSSMSConfirmation]: 'CORS Reminder SMS Sent',
  [translationKey.LabelSMSDataURLCopy]: 'Copy',
  [translationKey.LabelSMSDataURLCopied]: 'URL Copied',
  [translationKey.LabelSMSNoneScheduled]: 'None scheduled',
  [translationKey.LabelSMSNoneSent]: 'None Sent',
  [translationKey.LabelSometimes]: 'Sometimes',
  [translationKey.LabelSteadyAverage]: 'Steady average',
  [translationKey.LabelSupport]: 'Support',
  [translationKey.LabelSurveyName]: 'Survey Name',
  [translationKey.LabelTelephone]: 'Telephone',
  [translationKey.LabelTermsAndConditions]: 'Terms and Conditions',
  [translationKey.LabelThanks]: 'Thanks',
  [translationKey.LabelTickIfTestAccount]: 'Tick this box if this is a test account.  Test accounts still use a license but are not included in reporting.',
  [translationKey.LabelTickTermsAndConditionsIAgreeTo]: 'I agree to ',
  [translationKey.LabelTickTermsAndConditionsIHaveReadAndUnderstand]: ' and have read and understood the ',
  [translationKey.LabelTimeBelowAverage]: '{{mins}}m below average',
  [translationKey.LabelTimeSpent]: 'Time spent',
  [translationKey.LabelTime]: 'time',
  [translationKey.LabelToViewMoreAttemptsInstructions]: ' - To view more attempts, hold and drag graph area to scroll',
  [translationKey.LabelTotalDeactivated]: 'Total Deactivated',
  [translationKey.LabelTotalDuels]: 'Total duels',
  [translationKey.LabelTotalInvitesSent]: 'Total Invites Sent',
  [translationKey.LabelTotalLicensesAllocatedTo]: 'Total licenses allocated to',
  [translationKey.LabelTotalLicensesAvailable]: 'Total licenses available',
  [translationKey.LabelTotalLicensesUsed]: 'Total licenses used: {{used}}',
  [translationKey.LabelTotalNumberOfDuels]: 'Total number of duels',
  [translationKey.LabelTotalStartedPlaying]: 'Total Started Playing',
  [translationKey.LabelTotalSurveyScore]: 'TOTAL SCORE: {{total}}',
  [translationKey.LabelTotalTimePlaying]: 'Total time spent playing',
  [translationKey.LabelTotal]: 'total',
  [translationKey.LabelUploadDistributors]: 'Upload Distributors',
  [translationKey.LabelUrl]: 'URL',
  [translationKey.LabelUsage]: 'usage',
  [translationKey.LabelUsers]: 'users',
  [translationKey.LabelWebhookSettings]: 'Webhook Settings',
  [translationKey.LabelWebhookUrl]: 'Webhook URL',
  [translationKey.LabelWorkAddress]: 'Work Address',
  [translationKey.LabelWorkPhone]: 'Work Phone',
  [translationKey.LabelWorryScaleScore]: 'Worry scale score',
  [translationKey.LabelYoungFirstName]: 'Young Person First Name',
  [translationKey.LabelYoungLastName]: 'Young Person Last Name',
  [translationKey.LabelYoungPeoplePlayingTheGame]: 'Young people playing the game',
  [translationKey.LabelYoungPeopleNotYetStartedTab]: 'NOT YET STARTED ({{ count }})',
  [translationKey.LabelYoungPeoplePlayingTheGame]: 'PLAYING THE GAME ({{ count }})',
  [translationKey.LabelYoungPeopleDeactivatedTab]: 'DEACTIVATED ({{ count }})',
  [translationKey.LabelYoungPeoplePlayingLumiNova]: 'NO. OF YP PLAYING LUMI NOVA',
  [translationKey.LabelYoungPeopleWhoPlayed]: 'Young people who played',
  [translationKey.LabelYoungPeopleWorriedDuringChallenges]: 'Young people who say they feel \'quite worried\' or \'very worried\' during in-game challenges',
  [translationKey.LabelYoungPersonDOB]: 'Young Person Date of Birth',
  [translationKey.LabelYourInformationIsSecure]: 'Your information is stored securely by BFB Labs.',
  [translationKey.LinkForgotPassword]: 'Forgot password?',
  [translationKey.LinkNeedSupport]: 'Need help? Please contact <0>support@bfb-labs.com</0>',
  [translationKey.ListCorsEverything]: 'Everything (How is everything going?)',
  [translationKey.ListCorsFamily]: 'Family (How are things in my family?)',
  [translationKey.ListCorsMe]: 'Me (How am I doing?)',
  [translationKey.ListCorsSchool]: 'School (How am I doing at school?)',
  [translationKey.MessageConfirmDeleteEntity]: 'Are you sure you want to delete this entity as it contains active users?',
  [translationKey.MessageDistributorsWillAlsoBeRemoved]: '{{list}} will also be removed',
  [translationKey.MessageProfileCreated]: 'An automatic SMS will now go to the provided phone number with a game download link',
  [translationKey.MessageProfileCreated]: 'PROFILE CREATED',
  [translationKey.NavbarAdmins]: 'Admins',
  [translationKey.NavbarChampionsOfShenghaAggregateData]: 'Champions of the Shengha Aggregated Data',
  [translationKey.NavbarDashboard]: 'Dashboard',
  [translationKey.NavbarFAQs]: 'FAQs',
  [translationKey.NavbarHowItWorks]: 'How it works',
  [translationKey.NavbarLumiNovaAggregateData]: 'Lumi Nova Aggregated Data',
  [translationKey.NavbarMonthlyReports]: 'Monthly Reports',
  [translationKey.NavbarOrganisations]: 'Organisations',
  [translationKey.NavbarResources]: 'Resources',
  [translationKey.NavbarYoungPeople]: 'Young People',
  [translationKey.ScaleZeroToTen]: '0 - 1 - 2 - 3 - 4 - 5 - 6 - 7 - 8 - 9 - 10',
  [translationKey.Settings]: 'Settings',
  [translationKey.SignIn]: 'Sign in',
  [translationKey.TabActive]: 'Active',
  [translationKey.TabDeactivated]: 'Deactivated',
  [translationKey.TabDeleted]: 'Deleted',
  [translationKey.TabInvited]: 'Invited',
  [translationKey.TextAllDone]: 'All done! We\'ve sent you a text message to get started.',
  [translationKey.TextCheckForLumiNovaDownloadLink]: 'Check your mobile phone and click on the link we\'ve sent you to get your Lumi Nova game key and download link',
  [translationKey.TextCorsInformationOne]: 'The Child Outcome Rating Scale (CORS) is a four-item scale that assesses a parent or guardian\'s perceptions of their child’s problems during therapeutic treatment (Lumi Nova):',
  [translationKey.TextCorsInformationThree]: 'Individual responses for the CORS are calculated based on the point selected on each item\'s line. Each line indicates a scale from 0-10, inclusive of decimals, with 0.0 as the furthest left point and 10.0 as the furthest right point. Points selected on the left indicate a lower score, whereas a point selected on the right indicates a higher score. ',
  [translationKey.TextCorsInformationTwo]: 'The CORS measure is sent to the guardian via SMS on a weekly basis from the start of the intervention until the end. The total score for each measure is represented on the graph above.',
  [translationKey.TextCutOffScoresInformation]: 'The CORS cut off score between the clinical population and the non-clinical population for carer reporting on child is 28.',
  [translationKey.TextEnterEmailForPasswordReset]: 'Enter your email to reset your password',
  [translationKey.TextGboInformationOne]: 'GBOs are a way of evaluating progress towards a goal in the digital therapeutic game “Lumi Nova: Tales of Courage” with children and young people. GBOs compare how far a child or young person feels they have moved towards reaching their chosen goal at the beginning of the game and every week going forward.',
  [translationKey.TextGboInformationTwo]: 'Progress toward a chosen goals is rated weekly by the child on a scale from 0 (not close to reaching my goal/no progress) to 10 (very close to reaching my goal/goal has been reached). The outcome is the amount of movement along the scale from the start date of a goal to the end of a goal/use of the intervention.',
  [translationKey.TextHubDownForMaintenance]: 'The hub is under going some scheduled maintenance to ensure you get the best experience!',
  [translationKey.TextLumiNovaHasBeenProvidedBy]: 'Lumi Nova has been provided to you through {{entity}}',
  [translationKey.TextMaintenanceLength]: 'The maintenance will only take a few hours at the most. Please check again later.',
  [translationKey.Title2FAHeading]: 'Two-Factor Authentication Required',
  [translationKey.Title2FADescription]: 'Your account is protected with two-factor authentication. Please enter the code sent to your email address.',
  [translationKey.TitleAchieved]: 'ACHIEVED',
  [translationKey.TitleActive]: 'Active',
  [translationKey.TitleAddress]: 'Address',
  [translationKey.TitleAssignSelfSignUpDistributor]: 'Assign Self Sign Up Distributor',
  [translationKey.TitleBackOnlineSoon]: 'Tweet Twoo! Sorry for the inconvenience, we\'ll be back online soon!',
  [translationKey.TitleBack]: 'Back',
  [translationKey.TitleBestWeeksTotalTime]: 'Best week\'s total time',
  [translationKey.TitleBreakdownByDistributor]: 'Breakdown by Distributor',
  [translationKey.TitleBreakdownByEntity]: 'Breakdown by Entity',
  [translationKey.TitleChallenges]: 'CHALLENGES',
  [translationKey.TitleChampionsOfTheShengha]: 'Champions of the Shengha Pro',
  [translationKey.TitleChooseAnIntervention]: 'Choose an intervention below to begin:',
  [translationKey.TitleClosenessToGoal]: 'How close do you feel to reaching your goal today?',
  [translationKey.TitleCompletedSurveysColon]: 'Completed Surveys: ',
  [translationKey.TitleCompleted]: 'completed',
  [translationKey.TitleConfirmYoungProfile]: 'CONFIRM YOUNG PERSON PROFILE & SEND SMS TO GUARDIAN',
  [translationKey.TitleCorsInformation]: 'Information about CORS:',
  [translationKey.TitleCorsResult]: '{{name}}\'s Child Outcome Rating Scale (CORS) Results',
  [translationKey.TitleCors]: 'cors',
  [translationKey.TitleCotsGame]: 'Champions of the Shengha Game',
  [translationKey.TitleCotsKeysAvailable]: 'Organisation Champions of the Shengha Game Keys Available: {{ keys }}',
  [translationKey.TitleCreateADistributor]: 'Create a Distributor',
  [translationKey.TitleCreateAnOrganisation]: 'Create an Organisation',
  [translationKey.TitleCreateEntity]: 'Create an Entity',
  [translationKey.TitleCreateYourAccount]: 'Create your account',
  [translationKey.TitleCreatedAt]: 'Created At',
  [translationKey.TitleCreated]: 'Created',
  [translationKey.TitleCustomSupportInfo]: 'Custom Support Info',
  [translationKey.TitleCustomTitle]: 'Custom Title',
  [translationKey.TitleCustomizeSignUpPageUrl]: 'Customise Sign Up Page URL',
  [translationKey.TitleCutOffForCarerCorsReport]: 'Cut off for carer reporting on child score (28)',
  [translationKey.TitleCutOffScores]: 'Cut off scores:',
  [translationKey.TitleDashboard]: 'Dashboard',
  [translationKey.TitleDeactivateGameKey]: 'DEACTIVATE GAME KEY',
  [translationKey.TitleDeactivatedOn]: 'Deactivated On',
  [translationKey.TitleDeleteYoungPerson]: 'DELETE YOUNG PERSON',
  [translationKey.TitleDistributorAdminSignIn]: 'Distributor/Admin Sign in',
  [translationKey.TitleDistributorAdmin]: 'Distributor/Admin',
  [translationKey.TitleDistributor]: 'Distributor',
  [translationKey.TitleEditDistributorName]: 'Edit {{name}}',
  [translationKey.TitleEditEntity]: 'Edit {{ name }}',
  [translationKey.TitleEditOrganisationName]: 'Edit {{name}}',
  [translationKey.TitleEmail]: 'Email',
  [translationKey.TitleEnableCustomSupportInfo]: 'Enable Custom Support Info',
  [translationKey.TitleEnableSelfSignUp]: 'Enable Self Sign Up Page',
  [translationKey.TitleEnableWebhook]: 'Enable Webhook',
  [translationKey.TitleEntityLogo]: 'Entity Logo',
  [translationKey.TitleEntitySettings]: '{{ entityName }} Settings',
  [translationKey.TitleEntity]: 'Entity',
  [translationKey.TitleError]: 'Error',
  [translationKey.TitleFaqCannotFindWhatYouAreLookingFor]: 'CAN\'T FIND WHAT YOU\'RE LOOKING FOR?',
  [translationKey.TitleFaqChoosingGamePlayers]: 'CHOOSING GAME PLAYERS',
  [translationKey.TitleFaqGameMaintenance]: 'GAME MAINTENANCE',
  [translationKey.TitleFaqSupportingGamePlayersAndGuardians]: 'SUPPORTING GAME PLAYERS AND THEIR GUARDIANS',
  [translationKey.TitleFaqSupportingGamePlayers]: 'SUPPORTING GAME PLAYERS',
  [translationKey.TitleFaqTraining]: 'TRAINING',
  [translationKey.TitleFaq]: 'FAQ',
  [translationKey.TitleFileSize]: 'File Size',
  [translationKey.TitleFilename]: 'Filename',
  [translationKey.TitleFinalScore]: 'FINAL SCORE {{score}}',
  [translationKey.TitleForMindfulBreathing]: 'for mindful breathing',
  [translationKey.TitleGameKey]: 'GAME KEY',
  [translationKey.TitleGameStatus]: 'GAME STATUS',
  [translationKey.TitleGameTimeThisWeek]: 'TOTAL GAME TIME THIS WEEK',
  [translationKey.TitleGboInformation]: 'Information about GBOs:',
  [translationKey.TitleGoBack]: 'Go Back',
  [translationKey.TitleGoalBasedOutcomes]: '{{name}}\'s Goal Based Outcomes',
  [translationKey.TitleGoals]: 'GOALS',
  [translationKey.TitleGuardianAnxietySurveyResults]: '{{name}}\'s Guardian Anxiety Survey Results',
  [translationKey.TitleGuardianName]: 'Guardian name',
  [translationKey.TitleGuardianRelationship]: 'Guardian relationship',
  [translationKey.TitleInactiveUsersThisWeek]: 'INACTIVE USERS THIS WEEK',
  [translationKey.TitleInactive]: 'Inactive',
  [translationKey.TitleInviteDistributor]: 'Invite Distributor',
  [translationKey.TitleInvitedAt]: 'Invited At',
  [translationKey.TitleInvitedOn]: 'Invited on: {{- invitedOnDateString }}',
  [translationKey.TitleInvited]: 'INVITED',
  [translationKey.TitleInviteeEmail]: 'Invitee Email',
  [translationKey.TitleInviteeLink]: 'Invitee Link',
  [translationKey.TitleInviteeName]: 'Invitee Name',
  [translationKey.TitleKey]: 'key',
  [translationKey.TitleLastModified]: 'Last Modified',
  [translationKey.TitleLastSeen]: 'LAST SEEN',
  [translationKey.TitleLastUsedDate]: 'Last logged in: {{- lastUsedDateString }}',
  [translationKey.TitleLicencesAllocated]: 'Licences Allocated',
  [translationKey.TitleLicensesUsed]: 'Total Licenses Used',
  [translationKey.TitleLumiNovaAccessFormGreatNews]: 'Great news - Lumi Nova is available in your area.',
  [translationKey.TitleLumiNovaAccessForm]: 'Complete this form to get access to Lumi Nova: Tales of Courage for your child',
  [translationKey.TitleLumiNovaDashboardReport]: 'Lumi Nova Dashboard Report',
  [translationKey.TitleLumiNovaKeysAvailable]: 'Organisation Lumi Nova Game Keys Available: {{ keys }}',
  [translationKey.TitleLumiNovaTalesOfCourage]: 'Lumi Nova: Tales of Courage',
  [translationKey.TitleLumiNovaYoungReport]: 'Lumi Nova Young Person Report',
  [translationKey.TitleLumiNova]: 'Lumi Nova',
  [translationKey.TitleMonthlyReport]: 'Monthly Report',
  [translationKey.TitleMissions]: 'MISSIONS',
  [translationKey.TitleName]: 'Name',
  [translationKey.TitleNotYetActivated]: 'Not yet activated',
  [translationKey.TitleNotYetStarted]: 'NOT YET STARTED',
  [translationKey.TitleOptedOut]: 'OPTED OUT',
  [translationKey.TitleOrganisationAdminSignIn]: 'Organisation Admin Sign in',
  [translationKey.TitleOrganisationAdmin]: 'Organisation Admin',
  [translationKey.TitleOrganisationDashboard]: 'Organisation Dashboard',
  [translationKey.TitleOrganisation]: 'Organisation',
  [translationKey.TitleOverviewForYoungPeople]: 'Overview report for all young people in ',
  [translationKey.TitleOverviewReportForYoungPeople]: 'Overview report for all your young people',
  [translationKey.TitleParentOnboarding]: "Parent onboarding",
  [translationKey.TitlePhone]: 'Phone',
  [translationKey.TitlePlayerGameProgress]: '{{ player }}\'s game progress',
  [translationKey.TitlePlayerGameTime]: '{{ player }}\'s game time',
  [translationKey.TitlePlayingTheGame]: 'PLAYING THE GAME',
  [translationKey.TitlePointPersonEmail]: 'Point Person Email',
  [translationKey.TitlePointPersonName]: 'Point Person Name',
  [translationKey.TitlePointPersonPhone]: 'Point Person Phone',
  [translationKey.TitlePointPerson]: 'Point Person',
  [translationKey.TitlePrivacyPolicy]: 'Privacy Notice',
  [translationKey.TitleReason]: 'Reason',
  [translationKey.TitleRepeated]: 'repeated',
  [translationKey.TitleResetPassword]: 'Reset Password',
  [translationKey.TitleResources]: 'Resources',
  [translationKey.TitleServiceUserReferenceField]: 'Service User Reference Field',
  [translationKey.TitleServiceUserReference]: 'SERVICE USER REFERENCE',
  [translationKey.TitleSessionsPlayed]: 'SESSIONS PLAYED',
  [translationKey.TitleShowServiceUserReference]: 'Show Service User Reference',
  [translationKey.TitleSorryProblem]: 'Sorry, there\'s been a problem',
  [translationKey.TitleStartedGame]: 'STARTED GAME',
  [translationKey.TitleTableDeactivated]: 'DEACTIVATED',
  [translationKey.TitleTableDistributors]: 'DISTRIBUTORS',
  [translationKey.TitleTableEntity]: 'ENTITY',
  [translationKey.TitleTableInvitedDate]: 'INVITED DATE',
  [translationKey.TitleTableInvitesSent]: 'INVITES SENT',
  [translationKey.TitleTableLastLoggedIn]: 'LAST LOGGED IN',
  [translationKey.TitleTableLicenses]: 'LICENSES',
  [translationKey.TitleTableStartedPlayingDate]: 'STARTED PLAYING DATE',
  [translationKey.TitleTableStartedPlaying]: 'STARTED PLAYING',
  [translationKey.TitleTableYoungPersonName]: 'YOUNG PERSON NAME',
  [translationKey.TitleTestAccountsInUse]: 'Test Accounts In Use',
  [translationKey.TitleThisWeeksAggregateReport]: 'This week’s aggregated report',
  [translationKey.TitleToHelpWorries]: 'to help reduce worries',
  [translationKey.TitleTotalGameSessions]: 'Total game sessions',
  [translationKey.TitleUnnamedCorsResults]: 'Child Outcome Rating Scale (CORS) Results',
  [translationKey.TitleUserEmail]: 'User Email',
  [translationKey.TitleUserName]: 'User Name',
  [translationKey.TitleUserUniqueLink]: 'User Unique Link',
  [translationKey.TitleUsers]: 'users',
  [translationKey.TitleValidUntil]: 'Valid Until',
  [translationKey.TitleVictoryLegendPercentageOfUsers]: 'Percentage of users who started by responding to reflection questions with:',
  [translationKey.TitleWebhookSettings]: 'Webhook Settings',
  [translationKey.TitleWebhookUrl]: 'Webhook URL',
  [translationKey.TitleWebsite]: 'Website',
  [translationKey.TitleWelcomePlayer]: 'Welcome {{player}},',
  [translationKey.TitleYesSubmit]: 'Yes, Submit',
  [translationKey.TitleYoungPerson]: 'YOUNG PERSON',
  [translationKey.TitleYoungPersonDetails]: 'YOUNG PERSON DETAILS',
  [translationKey.TitleYoungPersonInformation]: 'Young Person Information',
  [translationKey.TitleYoungPersonReflections]: 'YOUNG PERSON\'S REFLECTIONS',
  // Restrictions and custom error
  [translationKey.LabelRestrictPostCode]: 'Restrict Postcode',
  [translationKey.LabelRestrictSignupCountryTo]: 'Restrict signup country to',
  [translationKey.LabelAcceptPostCodesStartingWith]: 'Accept postcodes starting with',
  [translationKey.LabelAvailabilityText]: 'Availability text',
  [translationKey.LabelCustomSupportText]: 'Custom support text',
  [translationKey.LabelEnableCustomErrorMessage]: 'Enable Custom error message',
  [translationKey.LabelCustomErrorMessage]: 'Custom error message',
  [translationKey.LabelCustomErrorCTAMessage]: 'Custom error CTA message',
  [translationKey.LabelCustomErrorCTAURL]: 'Custom error CTA URL',
  [translationKey.TitleRestrictionSettings]: 'Restriction Settings',
  [translationKey.SubTitleAvailabilityText]: 'Custom text shown on the Postcode validation page to describe where the intervention is available.',
  [translationKey.SubTitleCustomSupportText]: 'Custom text shown on the Postcode validation page to describe additional support options for urgent/other support.',
  [translationKey.SubTitleExamplePatternText]:`Separate your codes using a comma ','`,
  [translationKey.InvalidPattern]:'Invalid pattern! Follow AB, BC, CD etc',
  [translationKey.InvalidPostcodeError]: 'Sorry, this Postcode is not recognised - please check it\'s correct and try again'
};

export default { translation };
